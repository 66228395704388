import { gql } from '@apollo/client'

export const ITEM_SOURCE_CONTROL_FRAGMENT = gql`
  fragment ItemSourceControlFragment on ItemSource {
    archived
    id
    active
    note
    publicNote
    autoTags
    skipItemIfTagContains
    canBeConvertedToCompanyOwned
    projectSearch {
      atLeastOneOf
      fetchAll
    }
    customerSearch {
      atLeastOneOf
      fetchAll
    }
    eqlProject {
      id
      name
      builderProjectIds
      projectCode
      primaryCompany {
        id
        name
      }
    }
    dataSource {
      id
      name
    }
    projectItemsCounts{
      visibleCount
    }
  }
`
