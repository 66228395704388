export default function string2Search(str, fetchAll) {
  const splitString =
    typeof str === 'string'
      ? str
          .split(',')
          .map((s) => s.trim())
          .filter((s) => s.length)
      : []
  return { atLeastOneOf: splitString, fetchAll: Boolean(fetchAll) }
}
